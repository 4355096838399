import { revalidateTag as revalidate } from 'next/cache';
import { CacheTags } from './constants/CacheTags';

export const revalidateTags = async (tags: CacheTags[]) => {
	try {
		// check if we are in the browser or server
		if (!process?.platform) {
			await fetch(`/api/revalidate?tags=${tags.join(',')}`, { cache: 'no-store' });
		} else {
			tags.forEach(tag => revalidate(tag));
		}
	} catch (error) {
		console.error(error);
	}
};
