'use client';

/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx } from '@emotion/react';
import Button from '@egalite/ui/Buttons';
import Typography from '@egalite/ui/Typography';
import Icon from '@egalite/ui/Icon';
import Link from 'next/link';



const RegisterProfile = () => {

	return (
		<div className="flex flex-col h-[calc(100vh-160px)] max-h-[700px] rounded-4xl md:rounded-6xl px-6 md:px-56 py-0 md:py-32 bg-transparent md:bg-brand-main text-center text-white max-w-[730px] justify-between">
			<div className="hidden md:block font-roboto">
				<Typography variant="headline-3" asEl="h3" className='mb-8'>
                    Cadastre-se
				</Typography>
				<Typography variant="body-3" className='mb-28'>
                    Olá candidato, você é novo por aqui?
				</Typography>
                <Link href="/candidato/cadastrar">
                    <Button variant="secondary" size="small" sm="large" md="gigantic" icon={<Icon name="portrait" />} className="w-full md:min-w-max md:w-auto bg-brand-light hover:!text-brand-main hover:!bg-white">
                        Criar Perfil
                    </Button>
                </Link>
			</div>
            <div>
                <Typography variant="body-2" className='text-base-grey-5 md:text-white md:hidden text-left'>
                    Você é candidato e não tem conta? <Link href="/candidato/cadastrar">Clique aqui</Link>
                </Typography>
                <Typography variant="body-2" className='text-base-grey-5 md:text-white text-left'>
                    Você é empresa e não tem conta? <Link href="/cadastroempresa">Clique aqui</Link>
                </Typography>
            </div>
		</div>
	);
};

export default RegisterProfile;

