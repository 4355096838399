/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

export const InputMessage = ({ state = null, children = null, ...props }) => {
	const getColor = (messageState, theme) => {
		if (messageState === 'error') {
			return theme.colors.error.dark;
		}
		return theme.colors.brand.dark;
	};

	if (children == null) {
		return null;
	}

	return jsx(
		'p',
		{
			...props,
			css: theme => ({
				...theme.typography.input.label,
				marginTop: 8,
				marginBottom: 0,
				fontWeight: 400,
				fontSize: '1.6rem',
				color: getColor(state, theme),
				...props.css,
			}),
		},
		...(Array.isArray(children) ? children : [children] ),
	);
};

export default InputMessage;
