'use client';
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx } from '@emotion/react';
import Button from '@egalite/ui/Buttons/';
import { InputField, InputPassword } from '@egalite/ui/Form';
import Cookies from 'js-cookie';
import Icon from '@egalite/ui/Icon';
import Typography from '@egalite/ui/Typography';
import useForm from '../../../lib/hooks/useForm';
import Link from 'next/link';
import { Suspense, useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { useRouter, useSearchParams } from 'next/navigation';
import { revalidateTags } from '../../../lib/revalidateTags';
import { CacheTags } from '../../../lib/constants/CacheTags';
import RegisterProfile from '../RegisterProfile';

const RegisterLink = ({ value, linkText, link }) => (
	<Typography variant="body-2">
		{value}
		<span>
			<Link href={link}>{linkText}</Link>
		</span>
	</Typography>
);

const LoginForm = () => {
	const router = useRouter();
	const searchParams = useSearchParams();

	const [formController, getFieldProps, { loading, submitting, success, errors, data, setErrors }] = useForm({
		initialValues: { login: '', password: '' },
		validationSchema: yup.object().shape({
			login: yup.string().required('Email é obrigatório!'),
			password: yup.string().min(6, 'Senha deve ter pelo menos 6 caracteres!').required('Senha é obrigatório!'),
		}),
		mutation: async values =>
			fetch('/api/v1/auth/login', {
				method: 'POST',
				body: JSON.stringify(values),
				mode: 'cors',
				cache: 'no-store',
				credentials: 'same-origin',
				redirect: 'follow',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(res => {
					return res.json();
				})
				.then(async data => {
					if (data.success && data?.data?.token) {
						revalidateTags([CacheTags.User]);
					}

					return data;
				})
				.catch(err => {
					console.log(err);
				}),
		onSuccess: useCallback(
			(data = null) => {
				// userStore.isLogged = data != null;
				// userStore.data = data;
				if (searchParams.get('redirectUrl')) {
					window?.location?.assign(searchParams.get('redirectUrl') ?? '/');
				} else {
					// router.push(`/${data?.type}`);
					if (data?.type === 'company') {
						router.push('/empresa');
					} else if (data?.type === 'candidate') {
						router.push('/candidato');
					} else {
						router.push('/');
					}
				}
			},
			[router, searchParams],
		),
	});

	useEffect(() => {
		Cookies.remove('token');
		//TODO make this a acceptable solutionfale
		const host = window.location.hostname.slice(window.location.hostname.indexOf('inclui')) ?? 'localhost';
		Cookies.remove('token', { domain: '.' + host });

		router.refresh();
	}, []);

	return (
		<div className="container mx-auto my-20 flex flex-col md:flex-row gap-0 md:gap-48 items-center justify-center">
			<form onSubmit={formController.handleSubmit}>
				<fieldset disabled={loading as boolean}>
					<div className="rounded-3xl md:bg-white -mx-8 px-8 md:px-16 pt-24 pb-32 md:shadow-md">
						<Typography variant="headline-4" sm="headline-3" className="text-center text-base-grey-5 mb-20">
							Login
						</Typography>
						<div className="flex flex-col gap-12 mb-8 max-w-none md:max-w-[400px]">
							<InputField
								leftIcon={<Icon className="fi fi-br-envelope text-base-grey-4 !absolute !ml-7 !text-[22px]" />}
								xStyle={{ marginTop: 0, marginBottom: 0, flex: '1 0 100%' }}
								id="login"
								type="email"
								label={'Email'}
								inputProps={{
									xStyle: { paddingLeft: 48, '&:hover, &:focus': { paddingLeft: 47 } },
								}}
								{...getFieldProps('login')}
							/>
							<InputPassword
								leftIcon={<Icon icon="lock" className=" text-base-grey-4 !absolute !ml-7 !text-[22px]" />}
								xStyle={{ marginTop: 0, marginBottom: 0, flex: '1 0 100%' }}
								id="password"
								type="password"
								autoComplete="password"
								label={'Senha'}
								inputProps={{
									xStyle: { paddingLeft: 48, '&:hover, &:focus': { paddingLeft: 47 } },
								}}
								{...getFieldProps('password')}
							/>
						</div>
						<Typography variant="body-2" className="text-right text-brand-main mb-24">
							<Link href="/login/recover">{'Esqueceu a senha?'}</Link>
						</Typography>
						{errors != null &&
							(Array.isArray(errors) ? errors : [errors]).map((err, index) => (
								<Typography key={`err-${index}`} variant="body-2" className="text-error-dark">
									<span dangerouslySetInnerHTML={{ __html: err }} />
								</Typography>
							))}
						<div className="flex flex-col mt-24">
							<Button type="submit" variant="primary" disabled={submitting} className="min-w-0">
								{'Entrar'}
							</Button>
						</div>
					</div>
				</fieldset>
			</form>
			<RegisterProfile />
		</div>
	);
};


const Suspended = () => {
	return <Suspense fallback={<div>Carregando...</div>}><LoginForm /></Suspense>;
}

export default Suspended;
